import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import styles from './404.module.scss'

export default ({ location }) => (
  <Layout location={location} back footer>
    <Helmet>
      <title>Not found</title>
    </Helmet>
    <div className={styles.Four}>
      <div className={styles.FourContent}>
        <div>
          <h1>
            4<span className={styles.LobbyBoy} />4
          </h1>
          <p>we couldn't find that page</p>
        </div>
      </div>
    </div>
  </Layout>
)
